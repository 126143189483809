import { render, staticRenderFns } from "./AllOrder.vue?vue&type=template&id=9384a08e&scoped=true"
import script from "./AllOrder.vue?vue&type=script&lang=js"
export * from "./AllOrder.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9384a08e",
  null
  
)

export default component.exports